import {useEffect} from 'react';
// useAnalytics.js
export const useAnalytics = () => {
  useEffect(() => {
    const handleRouteChange = () => {
      if (window.gtag) {
        window.gtag('config', 'G-8FDE1L1RFY', {
          page_path: window.location.pathname,
        });
      }
    };

    // Listen for route changes
    window.addEventListener('locationchange', handleRouteChange);

    return () => {
      window.removeEventListener('locationchange', handleRouteChange);
    };
  }, []);
};
