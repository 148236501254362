import CSV2JSON from "@jordibosch20/software_tools_package/dist/pages/CSV2JSON.js"
import styles from "./App.module.css";
import { getCSVToJSON } from "@jordibosch20/software_tools_package/dist/utils/csv2json.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <CSV2JSON title={"CSV to JSON"} f={getCSVToJSON}></CSV2JSON>
      </div>
      <div className={styles.text}>
        <h3>Why Convert CSV to JSON?</h3>
        <p><strong>CSV (Comma Separated Values)</strong> files are a staple in data storage. They're simple, widely used, and can be opened by almost any spreadsheet software under the sun. However, when it comes to web applications or data interchange, <strong>JSON (JavaScript Object Notation)</strong> takes the crown. JSON's format is not only easy for humans to understand but also for machines to parse and generate, making it the go-to format for web developers and anyone involved in software development.</p>
        <p>So, why not just stick with one format? Well, it's not always that simple. Sometimes, you're handed a dataset in CSV format that needs to be integrated into a web application, or perhaps you're working with tools that primarily output data in CSV format but need to consume it in JSON. That's where our converter shines, bridging the gap between these two worlds.</p>
        <h3>How Does It Work?</h3>
        <p>Our CSV to JSON Converter is a marvel of simplicity. Here's a quick rundown:</p>
        <ol>
        <li><strong>Upload Your CSV File</strong>: Drag and drop your file or select it from your device. No size limits to worry about.</li>
        <li><strong>Let the Magic Happen</strong>: With a click of a button, our converter processes your file, meticulously turning those comma-separated values into a beautifully structured JSON format.</li>
        <li><strong>Download Your JSON</strong>: In mere moments, your new JSON file is ready to go. Download it directly to your device, with no fuss.</li>
        </ol>
        <h3>Who Can Benefit?</h3>
        <ul>
        <li><strong>Developers</strong>: Integrate CSV data into your web applications with ease.</li>
        <li><strong>Data Analysts</strong>: Transform your datasets for better data visualization tools.</li>
        <li><strong>Educators and Students</strong>: Teach and learn data manipulation without the complexity.</li>
        <li><strong>Small Business Owners</strong>: Manage your data more effectively, without needing to hire experts.</li>
        </ul>
        <h3>FAQs</h3>
        <p><strong>Q: Do I need to create an account to use the converter?</strong> A: Not at all! Our tool is accessible to anyone, anytime, without the need for an account.</p>
        <p><strong>Q: Can I convert files in bulk?</strong> A: Currently, our tool processes one file at a time, ensuring each conversion receives the attention to detail it deserves.</p>
        <p><strong>Q: Is my data safe?</strong> A: Absolutely. Your privacy is paramount. Uploaded files are converted and executed on client side, thus, it never arribes to any external server</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;